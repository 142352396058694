import z from 'zod';
export const QuestObjectiveCustomVulnerabilitiesSchema = z.object({
    kind: z.literal('learning-outcome'),
    rootCategoryId: z.string().min(1).brand(),
    categoryId: z.string().min(1).brand(),
    subcategoryId: z.string().min(1).brand(),
});
const BaseCreateQuestQueryParametersSchema = z.object({
    name: z.string().optional(),
    origin: z.literal('vulnerability-report').optional(),
    customVulnerabilities: z
        .string()
        .transform((val) => val === null || val === void 0 ? void 0 : val.split(','))
        .optional(),
});
export const createLmsQuestQueryParametersSchema = BaseCreateQuestQueryParametersSchema;
export const createPlatformQuestQueryParametersSchema = BaseCreateQuestQueryParametersSchema.extend({
    assignmentSelection: z.union([z.literal('company'), z.literal('team')]).optional(),
    teamIds: z
        .string()
        .transform((val) => val === null || val === void 0 ? void 0 : val.split(','))
        .optional(),
});
