import { jsx as _jsx } from "react/jsx-runtime";
import { useParams, useSearchParams } from 'react-router-dom';
import { CreateQuestLoader, EditQuestLoader } from './EditQuestLoaders';
export const EditQuestRoute = () => {
    const { questId } = useParams();
    if (!questId) {
        throw new Error('Missing questId');
    }
    return _jsx(EditQuestLoader, { questId: questId });
};
export const CreatePlatformQuestRoute = () => {
    const [searchParams] = useSearchParams();
    return _jsx(CreateQuestLoader, { kind: "platform", queryParameters: Object.fromEntries(searchParams.entries()) });
};
export const CreateLMSQuestRoute = () => {
    const [searchParams] = useSearchParams();
    return _jsx(CreateQuestLoader, { kind: "lms", queryParameters: Object.fromEntries(searchParams.entries()) });
};
