import { jsx as _jsx } from "react/jsx-runtime";
import { questsAdminUrls } from '../constants';
import { useTranslation } from 'react-i18next';
import { P, match } from 'ts-pattern';
import { usePlatformContext } from '../../platformContext';
import { useQuestsAdminApi } from '../hooks/useQuestsAdminApi';
import { Pending, useApiPatternLoad } from '../../Api.hooks';
import { extractValidCreateQuestQueryParameters, getAvailableCategories, getNewQuestState, getPciDssCategoryInformation, getSecurityConceptsCategoryInformation, } from '../utils';
import { usePlatformNavigate } from '../../navigation';
import { EditQuest, PendingEditQuest } from './EditQuest';
import { createLmsQuestQueryParametersSchema, createPlatformQuestQueryParametersSchema } from '../schemas';
export const CreateQuestLoader = ({ kind, queryParameters, }) => {
    const api = useQuestsAdminApi();
    const { t } = useTranslation();
    const { flagsEnabled, portalFrontendTranslation, metadata } = usePlatformContext();
    const navigate = usePlatformNavigate();
    if (kind === 'lms' && !flagsEnabled.includes('quests-lms')) {
        navigate.to({ pathname: questsAdminUrls.createPage, hash: '', search: '' });
    }
    const questConfigurationContext = useApiPatternLoad(api.questConfigurationContext.query, undefined);
    return match(questConfigurationContext)
        .with(Pending, () => _jsx(PendingEditQuest, { createMode: true, kind: kind }))
        .with(P.instanceOf(Error), () => {
        throw new Error(t('questsAdmin.pages.loadFailedError'));
    })
        .otherwise((data) => {
        const availableCategories = getAvailableCategories(data.availableCategorySlugs, metadata);
        const availableCategorySlugs = new Set(availableCategories.map((category) => category.id));
        const availableTeamIds = new Set(data.teams.map((team) => team.id));
        const validQueryParameters = extractValidCreateQuestQueryParameters(kind === 'lms' ? createLmsQuestQueryParametersSchema : createPlatformQuestQueryParametersSchema, queryParameters, availableCategorySlugs, availableTeamIds);
        return (_jsx(EditQuest, { initialQuestDetails: getNewQuestState({
                startDate: new Date(),
                kind,
                validQueryParameters,
            }), availableCategories: availableCategories, availableSecurityConcepts: getSecurityConceptsCategoryInformation(data.conceptualCourses, portalFrontendTranslation), pciDssCategories: getPciDssCategoryInformation(metadata, data.pciDssCategories, t), companyTeams: data.teams }));
    });
};
export const EditQuestLoader = ({ questId }) => {
    const api = useQuestsAdminApi();
    const { t } = useTranslation();
    const details = useApiPatternLoad(api.questDetails.query, { questId });
    const { metadata, portalFrontendTranslation } = usePlatformContext();
    return match(details)
        .with(Pending, () => _jsx(PendingEditQuest, { createMode: false, kind: "platform" }))
        .with(P.instanceOf(Error), () => {
        throw new Error(t('questsAdmin.pages.edit.loadFailedError'));
    })
        .otherwise((data) => {
        return (_jsx(EditQuest, { initialQuestDetails: data.questDetails, availableCategories: getAvailableCategories(data.questConfigurationContext.availableCategorySlugs, metadata), availableSecurityConcepts: getSecurityConceptsCategoryInformation(data.questConfigurationContext.conceptualCourses, portalFrontendTranslation), companyTeams: data.questConfigurationContext.teams, pciDssCategories: getPciDssCategoryInformation(metadata, data.questConfigurationContext.pciDssCategories, t) }));
    });
};
